import * as React from 'react'

import { Onboarding as OnboardingPage } from 'shared/pages'

import Layout from '../layouts/onboarding'

const Onboarding = () => (
  <Layout>
    <OnboardingPage
      type='BASIC'
      dialogTitle='Confirmar custodio de activos'
      dialogContentText={(
        <React.Fragment>
          Al hacer clic en el botón confirmas que Sugarblock SpA <strong>NO</strong> es
          custodio de activos, y que solo cumple la función de billetera digital. La
          función de custodia recae en <strong>BitGo Trust Company, Inc</strong>,
          uno de los principales custodios de activos digitales del mundo.
        </React.Fragment>
      )}
    />
  </Layout>
)

export default Onboarding
